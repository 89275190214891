<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button mat-icon-button (click)="toggleSideBar()">
            <mat-icon svgIcon="menu"></mat-icon>
        </button>
        <span class="title">{{title}}</span>
        <span class="spacer"></span>
        <a mat-flat-button href="https://iling.spb.ru/" color="primary" target="_blank">
            <mat-icon class="logo" svgIcon="logo"></mat-icon>
        </a>
    </mat-toolbar-row>
</mat-toolbar>