<div class="grid-container">
    <mat-grid-list cols="2" rowHeight="250px">
        <mat-grid-tile *ngFor="let item of items" [colspan]="item.cols" [rowspan]="item.rows">
            <mat-card class="dashboard-card">
                <mat-card-header>
                    <mat-card-title>
                        {{item.title}}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content">
                    <div>Card Content Here</div>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
</div>
