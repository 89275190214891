<mat-card class="card" fxFlex>
    <mat-card-header>
      <mat-card-title>{{title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ng-content></ng-content>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button>LIKE</button>
      <button mat-button>SHARE</button>
    </mat-card-actions>
  </mat-card>
