export enum AppType {
  AppSetting = 'app-settings',
  User = 'users',
  Role = 'roles',
  Project = 'projects',
  Index = 'indeces',
  IndexView = 'indeces',
  Header = 'headers',
  HeaderView = 'headers',
  Chunk = 'chunks',
  ChunkView = 'chunks',
  Element = 'elements',
  ElementView = 'elements',
  ChunkElementView = 'elements',
  Morph = 'morph-items',
  Interp = 'interps',
  Taxonomy = 'taxonomy-items',
  Note = 'notes',
  NoteLink = 'note-links'
};
